import Cookies from "js-cookie";

const TokenKey = "Admin-Token";
const WxTokenKey = "access_token";

const ExpiresInKey = "Admin-Expires-In";

export function getToken() {
  return Cookies.get(TokenKey);
}

export function setToken(token) {
  const time = new Date();
  time.setTime(time.getTime() + 60 * 60 * 1000 * 24);
  return Cookies.set(TokenKey, token, { expires: time });
}

export function getWxToken() {
  return Cookies.get(WxTokenKey);
}

export function setWxToken() {
  return Cookies.set(WxTokenKey, token);
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}

export function getExpiresIn() {
  return Cookies.get(ExpiresInKey) || -1;
}

export function setExpiresIn(time) {
  return Cookies.set(ExpiresInKey, time);
}

export function removeExpiresIn() {
  return Cookies.remove(ExpiresInKey);
}
